<template>
    <div>
      <div class="container">
        <p style="font-weight: bold; text-align: center">
          FORMULARIO PRESENTACIÓN DE COSTOS DE OPERACIÓN PARA SEGUIMIENTO
        </p>
        <!--<p style="font-weight: bold; text-align: center">
          Base legal: Decreto 1076 de 2015 o aquel que lo modifique o sustituya
        </p>-->
      </div>
      <div class="container">
        <p style="text-align: justify">
          Señor(a) usuario (a), los campos que tienen el * son obligatorios para
          radicar su solicitud. Le recomendamos diligenciar la mayoría o totalidad
          de la información solicitada.
        </p>
      </div>
      <div class="border border-dark container" style="background: #f5f5f5">
        <div style="text-align: left; margin-top: 20px">
          <label style="font-weight: bold"
            >Consulte el expediente al cual va asociar su solicitud con el número
            de identificación, si es un NIT consulte sin el número de
            verificación.<br />
          </label>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-input
              v-model="identificacionce"
              placeholder="No identificación:"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
          </div>
          <div class="col-md-2">
            <b-button variant="success" @click="consultaExp()" tabindex="0"
              >Consultar</b-button
            >
          </div>
          <div class="text-center" v-show="loading">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
          </div>
        </div>
        <br />
        <div class="row" v-show="showexp">
          <div class="col-md-12">
            <b-form-group label="Expedientes" style="text-align: left">
              <b-form-select v-model="stringExp">
                <b-form-select-option
                  :key="ind"
                  v-for="(tr, ind) in expedientes"
                  :value="tr"
                >
                  {{ tr.text }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <br /><br />
        </div>
      </div>
      <br />
      <div class="border border-dark container" style="background: #f5f5f5">
        <div style="text-align: left; margin-top: 20px">
          <label style="font-weight: bold">DATOS DEL SOLICITANTE </label>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="*Tipo de persona:" style="text-align: left">
              <b-form-radio-group
                id="radio-slots"
                v-model="form.selectedtipoper"
                name="selectedtipoper"
              >
                <b-form-radio value="1">Natural </b-form-radio>
                <b-form-radio value="2">Jurídica</b-form-radio>
              </b-form-radio-group>
              <b-form-radio-group
                v-if="form.selectedtipoper == 2"
                id="radio-slots"
                v-model="form.selectedtipoperjuridica"
                name="selectedtipoperjuridica"
              >
                <b-form-radio value="1">Jurídica pública </b-form-radio>
                <b-form-radio value="2">Jurídica privada </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="*Tipo de identificación:"
              style="text-align: left"
            >
              <b-form-radio-group
                id="radio-slots"
                v-model="form.selectedtipoident"
                name="selectedtipoident"
              >
                <b-form-radio value="10" v-if="form.selectedtipoper == 1"
                  >CC
                </b-form-radio>
                <b-form-radio value="26" v-if="form.selectedtipoper == 2"
                  >NIT
                </b-form-radio>
                <b-form-radio value="51" v-if="form.selectedtipoper == 1"
                  >Pasaporte
                </b-form-radio>
                <b-form-radio value="1" v-if="form.selectedtipoper == 2"
                  >Personería jurídica
                </b-form-radio>
                <b-form-radio value="11" v-if="form.selectedtipoper == 1"
                  >Cédula de extranjería
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
  
          <div class="col-md-12">
            <b-form-group style="text-align: left">
              <label
                style="text-align: left"
                v-if="form.selectedtipoident == 26 || form.selectedtipoident == 1"
              >
                *Razón Social</label
              >
              <b-form-input
                v-if="form.selectedtipoident == 26 || form.selectedtipoident == 1"
                v-model="form.nombre"
                placeholder="razón social"
                maxlength="180"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group>
              <div class="row">
                <div class="col-md-3" style="text-align: left">
                  <label
                    v-if="
                      form.selectedtipoident == 10 ||
                      form.selectedtipoident == 51 ||
                      form.selectedtipoident == 11
                    "
                    >*Primer Nombre</label
                  >
                  <b-form-input
                    placeholder="Primer Nombre"
                    v-if="
                      form.selectedtipoident == 10 ||
                      form.selectedtipoident == 51 ||
                      form.selectedtipoident == 11
                    "
                    v-model="form.nombre1"
                    maxlength="180"
                    onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                  ></b-form-input>
                </div>
                <div class="col-md-3" style="text-align: left">
                  <label
                    v-if="
                      form.selectedtipoident == 10 ||
                      form.selectedtipoident == 51 ||
                      form.selectedtipoident == 11
                    "
                    >Segundo Nombre</label
                  >
                  <b-form-input
                    placeholder="Segundo Nombre"
                    v-if="
                      form.selectedtipoident == 10 ||
                      form.selectedtipoident == 51 ||
                      form.selectedtipoident == 11
                    "
                    v-model="form.nombre2"
                    maxlength="180"
                    onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                  ></b-form-input>
                </div>
                <div class="col-md-3" style="text-align: left">
                  <label
                    v-if="
                      form.selectedtipoident == 10 ||
                      form.selectedtipoident == 51 ||
                      form.selectedtipoident == 11
                    "
                    >*Primer Apellido</label
                  >
                  <b-form-input
                    placeholder="Primer Apellido"
                    v-if="
                      form.selectedtipoident == 10 ||
                      form.selectedtipoident == 51 ||
                      form.selectedtipoident == 11
                    "
                    v-model="form.nombre3"
                    maxlength="180"
                    onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                  ></b-form-input>
                </div>
                <div class="col-md-3" style="text-align: left">
                  <label
                    v-if="
                      form.selectedtipoident == 10 ||
                      form.selectedtipoident == 51 ||
                      form.selectedtipoident == 11
                    "
                    >Segundo Apellido</label
                  >
                  <b-form-input
                    placeholder="Segundo Apellido"
                    v-if="
                      form.selectedtipoident == 10 ||
                      form.selectedtipoident == 51 ||
                      form.selectedtipoident == 11
                    "
                    v-model="form.nombre4"
                    maxlength="180"
                    onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                  ></b-form-input>
                </div>
              </div>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group style="text-align: left">
              <label
                v-if="
                  form.selectedtipoident == 10 ||
                  form.selectedtipoident == 26 ||
                  form.selectedtipoident == 51 ||
                  form.selectedtipoident == 1 ||
                  form.selectedtipoident == 11
                "
                >*Número de Identificación
              </label>
              <b-form-input
                v-model="form.identificacion"
                placeholder="No identificación:"
                v-if="
                  form.selectedtipoident == 10 ||
                  form.selectedtipoident == 51 ||
                  form.selectedtipoident == 11
                "
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                type="number"
                maxlength="20"
                @input="validarNumero"
              ></b-form-input>
              <div class="row">
                <div class="col-md-9">
                  <b-form-input
                    v-model="form.identificacion"
                    placeholder="No:"
                    v-if="
                      form.selectedtipoident == 26 || form.selectedtipoident == 1
                    "
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    type="number"
                    maxlength="20"
                    @input="validarNumero"
                  ></b-form-input>
                </div>
                <div class="col-md-3">
                  <b-form-input
                    v-model="form.digito"
                    placeholder="Dígito verificación:"
                    v-if="
                      form.selectedtipoident == 26 || form.selectedtipoident == 1
                    "
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    type="number"
                    maxlength="1"
                  ></b-form-input>
                </div>
              </div>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              label="*Dirección de correspondencia:"
              style="text-align: left"
              ><b-form-input
                v-model="form.dircorrespondencia"
                placeholder="Dirección de correspondencia"
                maxlength="95"
              ></b-form-input>
            </b-form-group>
          </div>
  
          <div class="col-md-6">
            <b-form-group label="*Departamento:" style="text-align: left">
              <b-form-select
                v-model="form.depart"
                :options="optionsdepartamento"
                @change="onChangeCiudad()"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="*Ciudad:" style="text-align: left">
              <b-form-select v-show="showciudad" v-model="ciudadcompleta">
                <b-form-select-option
                  :key="ind"
                  v-for="(tr, ind) in optionsciudades"
                  :value="tr"
                >
                  {{ tr.text }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Teléfono (s):" style="text-align: left"
              ><b-form-input
                v-model="form.tel"
                placeholder="Teléfono (s)"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                type="number"
                maxlength="35"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Fax:" style="text-align: left"
              ><b-form-input
                v-model="form.fax"
                placeholder="Fax"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                type="number"
                maxlength="35"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Correo electrónico:" style="text-align: left"
              ><b-form-input
                v-model="form.correo"
                placeholder="Correo electrónico"
                type="email"
                class="form-control"
                maxlength="55"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="*¿Autoriza la notificación mediante correo electrónico?"
              style="text-align: left"
              ><b-form-radio-group
                id="radio-slots"
                v-model="form.selectedcorreo"
                name="selectedcorreo"
              >
                <b-form-radio value="1">Si </b-form-radio>
                <b-form-radio value="2">No </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
  
          <div class="col-md-12">
            <b-form-group
              label="En caso contrario indique la dirección para notificación física:"
              style="text-align: left"
              ><b-form-input
                v-model="form.correodirnoti"
                placeholder="Correo electrónico o dirección de notificación"
                maxlength="50"
              ></b-form-input>
            </b-form-group>
          </div>
          <!--<div class="col-md-12">
          <b-form-group
            label="*Selecciona el tipo de notificación de respuesta"
            style="text-align: left"
          >
            <b-form-select
              v-model="form.selectedtiponoti"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>-->
        </div>
        <!------------------------------------------------------------------------------------->
  
        <!--------------------------cierre row finaliza el primer bloque de informacion ------------->
      </div>
      <br />
      <div class="border border-dark container" style="background: #f5f5f5">
        <div style="text-align: left; margin-top: 20px">
          <label style="font-weight: bold"
            >II. UBICACÍON DEL PROYECTO, OBRA O ACTIVIDAD
          </label>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="1. Nombre del predio:" style="text-align: left"
              ><b-form-input
                v-model="form.nombrepredio"
                placeholder="Nombre del predio"
                maxlength="300"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              label="2. *Dirección del predio:"
              style="text-align: left"
              ><b-form-input
                v-model="form.direccionpredio"
                placeholder="Dirección del predio"
                maxlength="95"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="3. *Departamento:" style="text-align: left">
              <b-form-select
                v-model="form.departpredio"
                :options="optionsdepart"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="*Municipio:" style="text-align: left">
              <b-form-select
                v-model="form.ciudadpredio"
                :options="optionsCiudad"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              label="Nombre centro poblado, vereda y/o corregimiento:"
              style="text-align: left"
              ><b-form-input
                v-model="form.nombrecentropoblado"
                placeholder="Nombre centro poblado, vereda y/o corregimiento:"
                maxlength="300"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
      <!------------------------------------------------------------------------------------->
      <br />
      <div
        class="border border-dark container"
        style="background: #f5f5f5"
        v-show="showbloque"
      >
        <div style="text-align: left; margin-top: 20px">
          <label style="font-weight: bold"
            >Si conoce el número del expediente de su proceso sancionatorio el
            cual debe estar en el oficio de notificación por favor ingreselo.<br />
          </label>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-input
              v-model="form.expedientemanual"
              placeholder="Expediente:"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9-]/g, '')"
              type="text"
              maxlength="50"
            ></b-form-input>
          </div>
          <div class="text-center" v-show="loading">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
          </div>
        </div>
        <br />
      </div>
      <br />
      <div class="border border-dark container" style="background: #f5f5f5">
        <div style="text-align: left; margin-top: 20px">
          <label style="font-weight: bold"
            >DISCRIMINACIÓN VALORES PARA DETERMINAR EL COSTO DEL PROYECTO, OBRA O
            ACTIVIDAD
          </label>
        </div>
        <div style="text-align: left; margin-top: 20px">
          <label
            >Resolución 0100 No. 0100-0222 de abril 14 de 2011<br />
            El valor del proyecto se compone de todos los costos relacionados con
            el proyecto y no solo de los específicos requeridos para usar el
            recurso natural. Ejemplo: en concesiones de agua superficial, para el
            cálculo de los costos de inversión, el valor del proyecto no solo
            incluye los costos de la obra específica de captación y distribución
            del recurso hídrico; también incluye los costos de los diferentes
            componentes del proyecto (obras, infraestructura, instalaciones,
            equipos, terrenos, cultivos, semovientes, adquisiciones y Planes de
            Manejo Ambiental) que tengan relación con el recurso y que permitan la
            obtención de beneficios al propietario.<br />
            En cumplimiento de lo anterior, es necesario y obligatorio que el
            usuario suministre la información de los costos de proyecto, obra o
            actividad para que la CVC liquide adecuada y técnicamente el valor del
            tope de la tarifa a cobrar.<br />
            En la siguiente tabla deben indicarse el valor de cada ítems en lo que
            aplique, para el tipo de proyecto, obra o actividad, o permiso,
            concesión, autorización o registro otorgado.
            <br />
          </label>
        </div>
        <div class="row">
          <table class="table">
            <tr>
              <td colspan="2">
                <center style="font-weight: bold">COSTOS DE OPERACIÓN</center>
                <br />
                (Comprende los costos requeridos para la administración, operación
                y mantenimiento durante la vida útil hasta el desmantelamiento del
                proyecto, obra o actividad)
              </td>
            </tr>
            <tr>
              <td>
                i) La mano de obra calificada y no calificada utilizada para la
                administración, operación y mantenimiento del proyecto, obra o
                actividad.
              </td>
              <td>
                <b-form-group
                  ><b-form-input
                    v-model="form.costo1"
                    @keyup="validateInput"
                    type="text"
                    maxlength="100"
                  ></b-form-input>
                </b-form-group>
              </td>
            </tr>
            <tr>
              <td>
                ii) Pagos de arrendamientos, servicios públicos, seguros y otros
                servicios requeridos.
              </td>
              <td>
                <b-form-group
                  ><b-form-input
                    v-model="form.costo2"
                    type="text"
                    @keyup="validateInput"
                    maxlength="100"
                  ></b-form-input>
                </b-form-group>
              </td>
            </tr>
            <tr>
              <td>
                iii) Todos los demás costos y gastos de operación que permiten la
                obtención de beneficios económicos para el propietario.
              </td>
              <td>
                <b-form-group
                  ><b-form-input
                    v-model="form.costo3"
                    type="text"
                    @keyup="validateInput"
                    maxlength="100"
                  ></b-form-input>
                </b-form-group>
              </td>
            </tr>
            <tr>
              <td style="font-weight: bold">
                VALOR TOTAL DEL PROYECTO, OBRA O ACTIVIDAD
              </td>
              <td>
                <b-form-group
                  ><b-form-input
                    type="text"
                    disabled
                    maxlength="100"
                    :value="suma"
                  ></b-form-input>
                </b-form-group>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br />
      <div class="border border-dark container" style="background: #f5f5f5">
        <div class="row" style="padding: 5px">
          <div class="col-md-12">
            <b-form-group
              label="* Descripción de la solicitud"
              style="text-align: left; font-weight: bold"
              ><b-form-textarea
                v-model="form.observacionradicado"
                maxlength="3900"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-11">
            <b-form-file
              v-model="file1"
              :state="Boolean(file1)"
              placeholder="Adjunta los documentos, peso maximo total archivos 50 Mb, peso maximo por cada archivo 10 Mb"
              drop-placeholder="Drop file here..."
              multiple
              browse-text="Buscar"
            ></b-form-file>
          </div>
          <div class="col-md-1">
            <b-button id="popover-button-variant" href="#" tabindex="0"
              >Ayuda</b-button
            >
            <b-popover
              target="popover-button-variant"
              variant="info"
              triggers="focus"
            >
              <template #title>Tutorial</template>
              De click
              <b-link
                href="../../../documentos/Guía Rápida Adjuntar documentos Tramites Web.pdf"
                target="blank"
                >aqui</b-link
              >
            </b-popover>
          </div>
        </div>
  
        <br /><br />
        <b-form-checkbox
          id="aceptacion"
          v-model="form.aceptacion"
          name="aceptacion"
          value="1"
          unchecked-value="0"
          >Acepto el uso de mis datos personales, tratamiento, protección y
          conservación de estos a CVC de acuerdo a lo establecido en la Ley 1581
          de 2012.<br />
          Al hacer clic el botón enviar, usted acepta la remisión de la PQRS a la
          CVC. Sus datos serán recolectados y tratados conforme con la Política de
          Tratamiento de Datos.
          <a
            href="https://www.cvc.gov.co/politicas_de_seguridad_y_privacidad_de_la_informacion"
            >Ver Política de protección de Datos Personales de la CVC.</a
          >
          En la opción consulta de
          <a href="https://consultaweb.cvc.gov.co/">PQRS </a> podrá verificar el
          estado de la respuesta.<br />
          En caso que la solicitud de información sea de naturaleza de identidad
          reservada, deberá efectuar el respectivo trámite ante la Procuraduría
          General de la Nación, haciendo clic en el siguiente
          <a
            href="https://www.procuraduria.gov.co/portal/solicitud_informacion_identificacion_reservada.page"
            >link:</a
          > </b-form-checkbox
        ><br />
        <div v-show="show">
          <b-button block variant="success" @click="enviar()"
            >Enviar solicitud</b-button
          >
        </div>
        <br />
        <!-- nuevo codgio-->
        <div class="text-center" v-show="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
          <div class="text-center">
            <strong>Enviando Solicitud</strong><br />
            <strong
              >IMPORTANTE: Si adjuntó archivos, por favor espere que termine la
              subida y salga un mensaje con el número de radicado. Si abandona el
              proceso, la solicitud quedará incompleta.....</strong
            >
          </div>
          <br />
  
          <div
            :key="ind"
            v-for="(tr, ind) in filesValide"
            class="row ml-8 mb-2 d-flex justify-content-center"
          >
            <b-progress
              :max="100"
              striped
              animated
              :variant="'primary'"
              class="w-75"
            >
              <b-progress-bar :value="tr.value === 0 ? 33 : tr.value">
                <span>
                  {{
                    tr.value !== 0
                      ? tr.color
                        ? `Se proceso el archivo '${tr.name} de manera correcta.`
                        : `No se pudo procesar el archivo '${tr.name}'.`
                      : `Se esta procesando el archivo '${tr.name}'`
                  }}
                  <strong></strong></span
              ></b-progress-bar>
            </b-progress>
          </div>
        </div>
        <!-- cierre nuevo codgio-->
        <br />
      </div>
  
      <br />
    </div>
    <!--cierre div principal-->
  </template>
  
  <script>
  import {
    rutafile,
    rutaciudad,
    rutaenviosolicitud,
    tiponotificacion,
    logo,
    rutaenvioconsultacostos,
  } from "../properties";
  import axios from "axios";
  import swal from "sweetalert";
  export default {
    data() {
      return {
        form: {
          selectedanonimo: "",
          tipotramite: "76",
          mediorecepcion: "4",
          observacionradicado: "",
          asunto: "PRESENTACIÓN DE COSTOS DE OPERACIÓN PARA SEGUIMIENTO",
          tiporadicado: "E",
          usuario: "arqu",
          idusuaasignado: "-1",
          pais: "1",
          selectedtipoper: "",
          selectedtipoperjuridica: "",
          selectedtipotram: "273", //si este 94416653 es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
          nombre: "",
          nombre1: "",
          nombre2: "",
          nombre3: "",
          nombre4: "",
          selectedtipoident: "",
          identificacion: "",
          digito: "",
          dircorrespondencia: "",
          ciudad: "",
          desciudad: "", //CAMBIO
          depart: "",
          tel: "",
          fax: "",
          correo: "",
          selectedcorreo: "",
          correodirnoti: "",
          selectedtiponoti: tiponotificacion,
          selectedrepre: "",
          selectedtipoperapo: "",
          selectedtipoperjuridicaapo: "",
          nombreapoderado: "",
          nombre1apo: "",
          nombre2apo: "",
          nombre3apo: "",
          nombre4apo: "",
          selectedtipoidentapo: "",
          identificacionapode: "",
          digitoapo: "",
          lugaridapoderado: "",
          dirapoderado: "",
          ciudadapoderado: "",
          desciudadapoderado: "", //CAMBIO
          departapoderado: "",
          telapoderado: "",
          faxapoderado: "",
          correoapoderado: "",
          selectedcalidadactua: "",
          aceptacion: "",
          nombrepredio: "",
          direccionpredio: "",
          departpredio: "",
          ciudadpredio: "",
          nombrecentropoblado: "",
          logo: logo,
          filesname: "",
          codexp: "",
          expedientemanual: "",
          resolucion: "",
          costo1: "",
          costo2: "",
          costo3: "",
          costototal: "",
        },
        identificacionce: "",
        expedientes: [],
        ciudadapoderado: "", //CAMBIO
        ciudadcompleta: "", //CAMBIO
        stringExp: "",
        loading: false,
        show: true,
        showciudad: false,
        showciudadapo: false,
        showanonimo: false,
        showbloque: false,
        showexp: false,
        file1: [],
        pesofile1: 0,
        nombrefiles: [],
        arryaJson: [],
        contadorarchivos: 0, // nueva
        contadorresponse: 0, // nueva
        filesValide: [], // nueva
        optionsCiudad: [
          { value: "1000", text: "SANTIAGO DE CALI" },
          { value: "20000", text: "ALCALA" },
          { value: "36000", text: "ANDALUCIA" },
          { value: "41000", text: "ANSERMANUEVO" },
          { value: "54000", text: "ARGELIA" },
          { value: "100000", text: "BOLIVAR" },
          { value: "109000", text: "BUENAVENTURA" },
          { value: "111000", text: "GUADALAJARA DE BUGA" },
          { value: "113000", text: "BUGALAGRANDE" },
          { value: "122000", text: "CAICEDONIA" },
          { value: "126000", text: "DARIEN" },
          { value: "130000", text: "CANDELARIA" },
          { value: "147000", text: "CARTAGO" },
          { value: "233000", text: "DAGUA" },
          { value: "243000", text: "EL AGUILA" },
          { value: "246000", text: "EL CAIRO" },
          { value: "248000", text: "EL CERRITO" },
          { value: "250000", text: "EL DOVIO" },
          { value: "275000", text: "FLORIDA" },
          { value: "306000", text: "GINEBRA" },
          { value: "318000", text: "GUACARI" },
          { value: "364000", text: "JAMUNDI" },
          { value: "377000", text: "LA CUMBRE" },
          { value: "400000", text: "LA UNION" },
          { value: "403000", text: "LA VICTORIA" },
          { value: "497000", text: "OBANDO" },
          { value: "520000", text: "PALMIRA" },
          { value: "563000", text: "PRADERA" },
          { value: "606000", text: "RESTREPO" },
          { value: "616000", text: "RIOFRIO" },
          { value: "622000", text: "ROLDANILLO" },
          { value: "670000", text: "SAN PEDRO" },
          { value: "736000", text: "SEVILLA" },
          { value: "823000", text: "TORO" },
          { value: "828000", text: "TRUJILLO" },
          { value: "834000", text: "TULUA" },
          { value: "845000", text: "ULLOA" },
          { value: "863000", text: "VERSALLES" },
          { value: "869000", text: "VIJES" },
          { value: "890000", text: "YOTOCO" },
          { value: "892000", text: "YUMBO" },
          { value: "895000", text: "ZARZAL" },
        ],
        optionsdepart: [{ value: "76", text: "VALLE DEL CAUCA" }],
        optionsdepartamento: [
          { value: "91", text: "AMAZONAS" },
          { value: "5", text: "ANTIOQUIA" },
          { value: "81", text: "ARAUCA" },
          {
            value: "88",
            text: "ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
          },
          { value: "8", text: "ATLANTICO" },
          { value: "11", text: "BOGOTA D.C" },
          { value: "13", text: "BOLIVAR" },
          { value: "15", text: "BOYACA" },
          { value: "17", text: "CALDAS" },
          { value: "18", text: "CAQUETA" },
          { value: "85", text: "CASANARE" },
          { value: "19", text: "CAUCA" },
          { value: "20", text: "CESAR" },
          { value: "27", text: "CHOCO" },
          { value: "23", text: "CORDOBA" },
          { value: "25", text: "CUNDINAMARCA" },
          { value: "94", text: "GUAINIA" },
          { value: "95", text: "GUAVIARE" },
          { value: "41", text: "HUILA" },
          { value: "44", text: "LA GUAJIRA" },
          { value: "47", text: "MAGDALENA" },
          { value: "50", text: "META" },
          { value: "52", text: "NARIÑO" },
          { value: "54", text: "NORTE DE SANTANDER" },
          { value: "86", text: "PUTUMAYO" },
          { value: "63", text: "QUINDIO" },
          { value: "66", text: "RISARALDA" },
          { value: "68", text: "SANTANDER" },
          { value: "70", text: "SUCRE" },
          { value: "73", text: "TOLIMA" },
          { value: "76", text: "VALLE DEL CAUCA" },
          { value: "97", text: "VAUPES" },
          { value: "99", text: "VICHADA" },
        ],
        optionsciudades: [],
        optionstiponoti: [
          { value: "2", text: "CORREO CERTIFICADO" },
          { value: "1", text: "CORREO ELECTRONICO" },
          { value: "7", text: "NOTIFICACIÓN PERSONAL" },
        ],
      };
    },
    computed: {
      suma() {
        let total =
          parseFloat(this.form.costo1) +
          parseFloat(this.form.costo2) +
          parseFloat(this.form.costo3);
  
        // Comprobar si total es un número válido
        if (isNaN(total) || !isFinite(total)) {
          return 0;
        }
  
        // Formatear el total usando Intl.NumberFormat
        const formatter = new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP",
        });
        return formatter.format(total);
      },
    },
    methods: {
      consultaExp() {
        this.expedientes = [];
        this.showbloque = false;
        if (this.identificacionce === "") {
          swal("Debes ingresar un número a consultar");
        } else {
          const axiosInstance = axios.create({
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
          this.loading = true;
          console.log(this.identificacionce);
          axiosInstance
            .post(rutaenvioconsultacostos, this.identificacionce)
            .then((response) => {
              console.log(response);
              console.log("length ", response.data.expediente);
              console.log(Object.keys(response.data.expediente).length === 0);
              if (response.data.expediente !== "cursor no trajo datos") {
                var arr = response.data.expediente.split("=");
  
                arr = arr.filter((el) => el != "");
                console.log("this.rutanew 796 ", arr);
  
                const nuevoObjeto = arr.map((elemento, index) => {
                  return { value: (index + 1).toString(), text: elemento };
                });
  
                console.log(nuevoObjeto);
                this.expedientes = nuevoObjeto;
  
                this.showexp = true;
                this.loading = false;
              } else if (response.data.expediente == "cursor no trajo datos") {
                this.loading = false;
                this.showexp = false;
                this.showbloque = true;
                swal(
                  "El número de Identificación consultado no tiene expedientes asociados"
                );
              }
            })
            .catch(function (error) {
              /*if (error.response.status === 500) {
                swal({
                  closeOnClickOutside: false,
                  text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                }).then((value) => {
                  if (value) {
                    window.location.href = "https://cvc.gov.co/";
                  }
                });
              }*/
              console.log(error);
              this.loading = false;
              this.show = true;
            });
        }
      },
      enviar() {
        console.log("--986--", this.form.codexp);

        if(this.form.codexp ==! ""){
          this.form.codexp = this.stringExp.text; //CAMBIO
          this.form.codexp = this.form.codexp.split("_")[0];
          console.log("----", this.form.codexp);
        }
        
        this.arryaJson = [];
        this.nombrefiles = [];
  
        if (this.file1.length > 0) {
          this.file1.forEach((file) => {
            this.nombrefiles.push(file.name);
          });
        }
        this.form.filesname = this.nombrefiles.toString();
        //console.log("arcPDF2 679", this.form.filesname);
  
        this.form.desciudad = this.ciudadcompleta.text; //CAMBIO
        this.form.ciudad = this.ciudadcompleta.value; //CAMBIO
        this.form.costototal =
          parseFloat(this.form.costo1) +
          parseFloat(this.form.costo2) +
          parseFloat(this.form.costo3);
        const axiosInstance = axios.create({
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
  
        if (this.validacioncampos(this.form) === true) {
          this.arryaJson.push(this.form);
          //console.log("this.arryaJson", this.arryaJson);
          this.loading = true;
          this.show = false;
  
          this.pesofile1 = 0;
          for (var i = 0; i < this.file1.length; i++) {
            this.pesofile1 += this.file1[i].size;
          }
          //console.log('this.pesofile1 ',this.pesofile1)
  
          // VALIDO SI CARGARON ARCHIVOS ADJUNTOS ----------------->
          if (this.file1.length > 0) {
            if (this.validarpesofile(this.file1) === true) {
              //console.log("valida archivos", this.validarpesofile(this.file1));
              // VALIDO SI TODOS LOS ARCHIVOS ADJUNTOS SUPERAN LOS 50 MB ----------------->
              if (this.pesofile1 > 50000000) {
                swal(
                  "El peso total de los archivos adjuntos supera los 50 Mb permitidos"
                );
                this.loading = false;
                this.show = true;
                //console.log('tamaño de los archivos -------- ',this.pesofile1)
                this.pesofile1 = 0;
              } else {
                // SE ENVIA LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
                axiosInstance
                  .post(rutaenviosolicitud, this.arryaJson)
                  .then((response) => {
                    let blob = this.b64toBlob(response.data.pdf);
                    //console.log("adjuntos blob ", blob);
  
                    this.file1.push(blob);
  
                    //console.log(response.data);
                    console.log("adjuntos ", this.file1.length);
                    if (this.file1.length > 0) {
                      this.contadorarchivos = this.file1.length;
                      this.filesValide = []; /////////////7nuevo
  
                      console.log("cantidad de archivos ", this.contadorarchivos);
                      this.file1.forEach((file) => {
                        /////////////7nuevo
                        this.filesValide.push({
                          name: file.name,
                          value: 0,
                          color: true,
                        });
  
                        const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                        const formData = new FormData();
                        var regex = /([^-\w.]*)/gm;
                        //console.log("nombre archivo", file.name);
                        var arcPDF2 = file.name.replace(regex, "");
                        arcPDF2 = arcPDF2.replaceAll(" ", "");
                        //console.log("nombre nuevo ", arcPDF2);
  
                        var blob = file.slice(0, file.size, file.type);
                        var newFile = new File([blob], arcPDF2, {
                          type: file.type,
                        });
  
                        formData.append("file", newFile);
                        formData.append("fileName", arcPDF2);
                        formData.append("radicado", response.data.radicado);
                        formData.append("usuario", response.data.usuario);
                        formData.append("ordenTrabajo", response.data.orden);
                        formData.append("idCorreo", response.data.correo);
  
                        // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                        axios
                          .post(rutafile, formData, {
                            /// envio archivos
                            headers: { "content-type": "multipart/form-data" },
                            maxRedirects: 0,
                          })
                          .then((respon) => {
                            this.filesValide[keyInd].value = 100; ///////////77nuevo
                            if (respon.status === 200) {
                              this.contadorresponse++;
                            } else {
                              ///////////77nuevo
                              this.filesValide[keyInd].color = false;
                            } ///////////77nuevo
  
                            this.resetForm();
                            //console.log("Respuesta envio adjuntos", respon);
  
                            if (this.contadorarchivos === this.contadorresponse) {
                              this.loading = false;
                              swal({
                                closeOnClickOutside: false,
                                text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                              }).then((value) => {
                                if (value) {
                                  window.location.href = "https://cvc.gov.co/";
                                }
                              });
                            }
  
                            this.show = true;
                          })
                          .catch(function (error) {
                            this.filesValide[keyInd].value = 100; ///////////77nuevo
                            this.filesValide[keyInd].color = false; ///////////77nuevo
                            if (
                              error.response.status === 500 ||
                              error.response.status === 404
                            ) {
                              swal({
                                closeOnClickOutside: false,
                                text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                              }).then((value) => {
                                if (value) {
                                  window.location.href = "https://cvc.gov.co/";
                                }
                              });
                              this.resetForm();
                              console.log("error 2022 ", error.response.status);
                            }
                            this.show = true;
                          });
                      });
                    } else {
                      this.resetForm();
                      swal({
                        closeOnClickOutside: false,
                        text: `...Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                      }).then((value) => {
                        if (value) {
                          window.location.href = "https://cvc.gov.co/";
                        }
                      });
                      this.loading = false;
                      this.show = true;
                    }
                  })
                  .catch(function (error) {
                    if (error.response.status === 500) {
                      swal({
                        closeOnClickOutside: false,
                        text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                      }).then((value) => {
                        if (value) {
                          window.location.href = "https://cvc.gov.co/";
                        }
                      });
                    }
                    this.loading = false;
                    this.show = true;
                  });
              }
            } else {
              swal("El peso de uno o mas archivos supera los 10 Mb permitidos");
              this.loading = false;
              this.show = true;
            }
          } else {
            // CUANDO NO CARGAR ARCHIVOS SE ENVIA SOLO LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
            axiosInstance
              .post(rutaenviosolicitud, this.arryaJson)
              .then((response) => {
                
                let blob = this.b64toBlob(response.data.pdf);
                
                this.file1.push(blob);
  
                console.log("adjuntos pdf backend", this.file1.length);
                
                if (this.file1.length > 0) {
                  this.contadorarchivos = this.file1.length;
                  this.filesValide = []; /////////////7nuevo
  
                  //console.log("cantidad de archivos ", this.contadorarchivos);
                  this.file1.forEach((file) => {
                    /////////////7nuevo
                    this.filesValide.push({
                      name: file.name,
                      value: 0,
                      color: true,
                    });
  
                    const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                    const formData = new FormData();
                    var regex = /([^-\w.]*)/gm;
                    //console.log("nombre archivo", file.name);
                    var arcPDF2 = file.name.replace(regex, "");
                    arcPDF2 = arcPDF2.replaceAll(" ", "");
                    //console.log("nombre nuevo ", arcPDF2);
  
                    var blob = file.slice(0, file.size, file.type);
                    var newFile = new File([blob], arcPDF2, {
                      type: file.type,
                    });
  
                    formData.append("file", newFile);
                    formData.append("fileName", arcPDF2);
                    formData.append("radicado", response.data.radicado);
                    formData.append("usuario", response.data.usuario);
                    formData.append("ordenTrabajo", response.data.orden);
                    formData.append("idCorreo", response.data.correo);
  
                    // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                    axios
                      .post(rutafile, formData, {
                        /// envio archivos
                        headers: { "content-type": "multipart/form-data" },
                        maxRedirects: 0,
                      })
                      .then((respon) => {
                        this.filesValide[keyInd].value = 100; ///////////77nuevo
                        if (respon.status === 200) {
                          this.contadorresponse++;
                        } else {
                          ///////////77nuevo
                          this.filesValide[keyInd].color = false;
                        } ///////////77nuevo
  
                        this.resetForm();
                        //console.log("Respuesta envio adjuntos", respon);
  
                        if (this.contadorarchivos === this.contadorresponse) {
                          this.loading = false;
                          swal({
                            closeOnClickOutside: false,
                            text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                          }).then((value) => {
                            if (value) {
                              window.location.href = "https://cvc.gov.co/";
                            }
                          });
                        }
  
                        this.show = true;
                      })
                      .catch(function (error) {
                        this.filesValide[keyInd].value = 100; ///////////77nuevo
                        this.filesValide[keyInd].color = false; ///////////77nuevo
                        if (
                          error.response.status === 500 ||
                          error.response.status === 404
                        ) {
                          swal({
                            closeOnClickOutside: false,
                            text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                          }).then((value) => {
                            if (value) {
                              window.location.href = "https://cvc.gov.co/";
                            }
                          });
                          this.resetForm();
                          console.log("error 2022 ", error.response.status);
                        }
                        this.show = true;
                      });
                  });
                }
  
                this.resetForm();
                swal({
                  closeOnClickOutside: false,
                  text: `.,.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                }).then((value) => {
                  if (value) {
                    window.location.href = "https://cvc.gov.co/";
                  }
                });
                this.loading = false;
                this.show = true;
              })
              .catch(function (error) {
                if (error.response.status === 500) {
                  swal({
                    closeOnClickOutside: false,
                    text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                  }).then((value) => {
                    if (value) {
                      window.location.href = "https://cvc.gov.co/";
                    }
                  });
                }
                this.loading = false;
                this.show = true;
              });
          }
        } else {
          console.log("fallo");
        }
      },
      validarpesofile(files) {
        var count = 0;
        //console.log(files.length);
        files.forEach((file) => {
          if (file.size > 10000000) {
            count++;
          }
        });
        //console.log("archivos con peso de mas de 10 mb", count);
        if (count == 0) {
          return true;
        } else {
          return false;
        }
      },
      resetForm() {
        this.form = {
          tipotramite: "",
          mediorecepcion: "4",
          observacionradicado: "",
          asunto: "",
          tiporadicado: "E",
          usuario: "arqu",
          idusuaasignado: "-1",
          pais: "1",
          selectedtipoper: "",
          selectedtipoperjuridica: "",
          selectedtipotram: "", //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
          nombre: "",
          nombre1: "",
          nombre2: "",
          nombre3: "",
          nombre4: "",
          selectedtipoident: "",
          identificacion: "",
          digito: "",
          dircorrespondencia: "",
          ciudad: "",
          depart: "",
          tel: "",
          fax: "",
          correo: "",
          selectedcorreo: "",
          correodirnoti: "",
          selectedtiponoti: "",
          selectedrepre: "",
          selectedtipoperapo: "",
          selectedtipoperjuridicaapo: "",
          nombreapoderado: "",
          nombre1apo: "",
          nombre2apo: "",
          nombre3apo: "",
          nombre4apo: "",
          selectedtipoidentapo: "",
          identificacionapode: "",
          digitoapo: "",
          lugaridapoderado: "",
          dirapoderado: "",
          ciudadapoderado: "",
          departapoderado: "",
          telapoderado: "",
          faxapoderado: "",
          correoapoderado: "",
          selectedcalidadactua: "",
          nombrepredio: "",
          direccionpredio: "",
          departpredio: "",
          ciudadpredio: "",
          nombrecentropoblado: "",
          codexp: "",
          expedientemanual: "",
          resolucion: "",
          costo1: "",
          costo2: "",
          costo3: "",
          costototal: "",
        };
      },
      b64toBlob(b64Data) {
        const names = "formulario.pdf";
        const newbase64 = "data:application/pdf;base64," + b64Data;
  
        let arr = newbase64.split(",");
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = window.atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
  
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
  
        let file_created = new File([u8arr], names, { type: mime });
  
        /*var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
  
        var blob = new Blob([file_created], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "fileName.pdf";
        a.click();*/
  
        return file_created;
      },
      validarNumero() {
      this.form.identificacion = this.form.identificacion.replace(/[^0-9]/g, ''); // Elimina cualquier carácter que no sea un número
      this.identificacionce = this.identificacionce.replace(/[^0-9]/g, '');
      },
      validacioncampos() {
        console.log("*", this.expedientes.length);
        console.log("**", this.form.codexp);
        if (this.expedientes.length > 0 && this.form.codexp === "") {
          swal("Debe seleccionar uno de los expedientes de la lista consultada");
        } else if (this.form.selectedtipoper === "") {
          swal("Debe seleccionar el tipo de persona");
        } else if (
          this.form.selectedtipoper === "2" &&
          this.form.selectedtipoperjuridica === ""
        ) {
          swal("Debe seleccionar el tipo de persona juridica");
        } else if (this.form.selectedtipoident === "") {
          swal("Debe seleccionar el tipo de identificación");
        } else if (
          (this.form.selectedtipoident === "26" ||
            this.form.selectedtipoident === "1") &&
          this.form.nombre === ""
        ) {
          swal("Debe ingresar un nombre o razón social");
        } else if (
          (this.form.selectedtipoident === "10" ||
            this.form.selectedtipoident === "51" ||
            this.form.selectedtipoident === "11") &&
          (this.form.nombre1 === "" || this.form.nombre3 === "")
        ) {
          swal("Debe ingresar un nombre y un apellido");
        } else if (this.form.identificacion === "") {
          swal("Debe ingresar la identificación");
        } else if (
          (this.form.selectedtipoident === "26" ||
            this.form.selectedtipoident === "1") &&
          this.form.digito === ""
        ) {
          swal("Debe ingresar el digito de verificación");
        } else if (this.form.dircorrespondencia === "") {
          swal("Debe ingresar la dirección de correspondencia");
        } else if (this.form.depart === "") {
          swal("Debe seleccionar el departamento de correspondencia");
        } else if (this.ciudadcompleta.value == undefined) {
          swal("Debe seleccionar la ciudad de correspondencia");
        } else if (this.form.selectedcorreo === "") {
          swal("Debe seleccionar si o no autoriza la notificación por correo");
        } else if (this.form.selectedcorreo === "1" && this.form.correo === "") {
          swal("Por favor ingresar un correo");
        } else if (
          this.form.selectedcorreo === "1" &&
          this.validEmail(this.form.correo) === false
        ) {
          swal("Por favor ingresar un correo valido");
        } else if (
          this.form.selectedcorreo === "2" &&
          this.form.correodirnoti === ""
        ) {
          swal("Por favor ingresar una dirección de notificación");
        } else if (this.form.observacionradicado === "") {
          swal("Debe ingresar una breve descripción de la solicitud");
        } else if (this.form.direccionpredio === "") {
          swal(
            "Debe ingresar la dirección del predio para el cual esta haciendo la solicitud"
          );
        } else if (
          this.form.direccionpredio !== "" &&
          this.validaAlfanumericosespeciales(this.form.direccionpredio) === false
        ) {
          swal(
            "Por favor validar la dirección ingresada solo se permite numeros letras y los caracteres .,#-/()"
          );
        } else if (this.form.departpredio === "") {
          swal(
            "Debe seleccionar el departamento del predio para el cual esta haciendo la solicitud"
          );
        } else if (this.form.ciudadpredio === "") {
          swal(
            "Debe seleccionar el municipio del predio para el cual esta haciendo la solicitud"
          );
        } else if (this.form.costo1 === "") {
          swal(
            "Debe Ingresar el costo La mano de obra calificada y no calificada utilizada para la administración, operación y mantenimiento del proyecto, obra o actividad."
          );
        } else if (this.form.costo2 === "") {
          swal(
            "Debe Ingresar el costo Pagos de arrendamientos, servicios públicos, seguros y otros servicios requeridos."
          );
        } else if (this.form.costo3 === "") {
          swal(
            "Debe Ingresar el costo Todos los demás costos y gastos de operación que permiten la obtención de beneficios económicos para el propietario."
          );
        } else if (this.form.costototal === 0) {
          swal(
            "La suma del Total de los costos presentados debe tener un valor mayor a 0."
          );
        } else if (this.form.aceptacion === "") {
          swal(
            "Debe aceptar el uso de mis datos personales, tratamiento, protección"
          );
        } else {
          return true;
        }
      },
      validEmail(email) {
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
        return re.test(email);
      },
      validaAlfanumericosespeciales(dir) {
        var regex = new RegExp("^[a-zA-Z0-9 .,#-/()]+$");
        if (!regex.test(dir)) {
          return false;
        }
      },
      onChangeCiudad() {
        if (this.form.depart !== "") {
          this.optionsciudades = [];
  
          this.$http
            .post(rutaciudad + `${this.form.depart}`)
            .then((response) => {
              for (var i in response.data) {
                var value = response.data[i].idciudad;
                var text = response.data[i].descciudad;
  
                var objeto = {
                  value: value,
                  text: text,
                };
                this.optionsciudades.push(objeto);
              }
              this.showciudad = true;
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      },
      onChangeCiudadapoderado() {
        if (this.form.departapoderado !== "") {
          this.optionsciudades = [];
  
          this.$http
            .post(rutaciudad + `${this.form.departapoderado}`)
            .then((response) => {
              for (var i in response.data) {
                var value = response.data[i].idciudad;
                var text = response.data[i].descciudad;
  
                var objeto = {
                  value: value,
                  text: text,
                };
                this.optionsciudades.push(objeto);
              }
              this.showciudadapo = true;
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      },
      validateInput() {
        // Usar una expresión regular para permitir solo números, puntos y comas
        this.form.costo1 = this.form.costo1.replace(/[^0-9.,]/g, "");
        this.form.costo2 = this.form.costo2.replace(/[^0-9.,]/g, "");
        this.form.costo3 = this.form.costo3.replace(/[^0-9.,]/g, "");
      },
    },
    mounted() {
      window.scroll({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped></style>
  