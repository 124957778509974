import { render, staticRenderFns } from "./Costos.vue?vue&type=template&id=06ef232f&scoped=true"
import script from "./Costos.vue?vue&type=script&lang=js"
export * from "./Costos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06ef232f",
  null
  
)

export default component.exports